import { genericErrorLog } from 'data/api/genericErrorMessage';
import { getWalesUseCase } from 'domain/use-cases/wale/getWales.use-case';
import { useEffect, useState } from 'react';
import { Wale } from 'domain/entities/wale.entity';

export const useWaleList = (): string[] => {
  const [waleIds, setWaleIds] = useState<string[]>([]);

  useEffect(() => {
    getWalesUseCase()
      .then((wales) => setWaleIds(wales.map((w) => w.waleId)))
      .catch(genericErrorLog);
  }, []);

  return waleIds;
};

export const useWales = (): Wale[] => {
  const [wales, setWales] = useState<Wale[]>([]);

  useEffect(() => {
    getWalesUseCase().then(setWales).catch(genericErrorLog);
  }, []);

  return wales;
};

export const useWaleListWithActiveFlag = (onlyActive: boolean): string[] => {
  const [waleIds, setWaleIds] = useState<string[]>([]);

  useEffect(() => {
    getWalesUseCase()
      .then((wales) =>
        setWaleIds(wales.filter((w) => !onlyActive || w.active).map((w) => w.waleId))
      )
      .catch(genericErrorLog);
  }, [onlyActive]);

  return waleIds;
};
