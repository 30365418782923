import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useWaleListWithActiveFlag } from 'presentation/hooks/useWaleList';
import { ConfigChange } from 'domain/entities/changeHistory.entity';
import { getWaleChangeHistoryUseCase } from 'domain/use-cases/wale/getWales.use-case';
import Select from 'components/Select';
import customStyles from 'components/Select/themes';
import {
  isBack,
  isValidWaleId,
  isWaleIdEastbound,
  isWaleIdNorthbound,
  isWaleIdSouthbound,
  isWaleIdWestbound,
  shortWaleId
} from 'presentation/helpers/waleId';
import { Clock } from 'react-feather';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { getRoadDateTime } from 'services/timeUtils';
import { BackToHomeBtn } from '../../components';
import ConfigDataTable from './ConfigDataTable';
import columns from './Columns';

const possibleExtraOptions = [
  {
    filterFunction: isWaleIdEastbound,
    value: 'Eastbound',
    label: 'Eastbound WALEs'
  },
  {
    filterFunction: (a: string) => isWaleIdEastbound(a) && isBack(a),
    value: 'Eastbound Back',
    label: 'Eastbound Back WALEs'
  },
  { filterFunction: isWaleIdWestbound, value: 'Westbound', label: 'Westbound WALEs' },
  {
    filterFunction: (a: string) => isWaleIdWestbound(a) && isBack(a),
    value: 'Westbound Back',
    label: 'Westbound Back WALEs'
  },
  { filterFunction: isWaleIdNorthbound, value: 'Northbound', label: 'Northbound WALEs' },
  {
    filterFunction: (a: string) => isWaleIdNorthbound(a) && isBack(a),
    value: 'Northbound Back',
    label: 'Northbound Back WALEs'
  },
  { filterFunction: isWaleIdSouthbound, value: 'Southbound', label: 'Southbound WALEs' },
  {
    filterFunction: (a: string) => isWaleIdSouthbound(a) && isBack(a),
    value: 'Southbound Back',
    label: 'Southbound Back WALEs'
  }
];

const BRIGHTNESS_SETTINGS = [
  'configurations.camera.params.ar.automatic_brightness.enable_modul',
  'configurations.camera.params.ar.brightness_general.target_0',
  'configurations.camera.params.flexwatch.CameraSetup.Exposure.Brightness_Day',
  'configurations.camera.params.flexwatch.CameraSetup.Image.Gamma_Day'
];

const WaleSettings = () => {
  // URL params
  const queryWaleId = useQueryParam('id');
  const INITIAL_STATE = queryWaleId ?? null;
  const [showOnlyActive, setShowOnlyActive] = useState(true);
  const [displayedWALEs, setDisplayedWALEs] = useState<string[]>([]);
  const [reRenderCount, setReRenderCount] = useState(0);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [configChanges, setConfigChanges] = useState<ConfigChange[]>([]);
  const [historyBtnConfig, setHistoryBtnConfig] = useState({
    msg: 'Select a WALE to see change history',
    disabled: true
  });
  const mapToOptions = (opts: string[]) =>
    opts.map((opt) => ({ value: opt, label: shortWaleId(opt) }));
  const [selectedState, setSelectedState] = useState<string | null>(INITIAL_STATE);
  const [hideBrightnessSettings, setHideBrightnessSettings] = useState(true);

  const waleList = useWaleListWithActiveFlag(showOnlyActive);
  const [extraSelectedOptions, setExtraSelectedOptions] = useState<
    { value: string; label: string; filterFunction: (a: string) => boolean }[]
  >([]);

  useEffect(() => {
    setExtraSelectedOptions(
      possibleExtraOptions.filter((option) => waleList.filter(option.filterFunction).length > 0)
    );
  }, [waleList]);

  useEffect(() => {
    if (selectedState === null) {
      setDisplayedWALEs(waleList);
      return;
    }
    const specialOption = extraSelectedOptions.find((a) => a.value === selectedState);
    if (specialOption) {
      setDisplayedWALEs(
        waleList.filter((wale: string) => wale && specialOption?.filterFunction(wale))
      );
    } else {
      setDisplayedWALEs(waleList.filter((wale: string) => wale && wale === selectedState));
    }
  }, [selectedState, showOnlyActive, waleList]);

  useEffect(() => setReRenderCount(reRenderCount + 1), [displayedWALEs]);

  useEffect(() => {
    if (selectedState && isValidWaleId(selectedState)) {
      getWaleChangeHistoryUseCase(selectedState).then(setConfigChanges);
    }
  }, [selectedState]);

  const handleWaleSelectionChange = (selection: { value: string } | null) => {
    setSelectedState(selection ? selection.value : null);
  };

  useEffect(() => {
    if (
      !selectedState ||
      possibleExtraOptions.filter((option) => option.value === selectedState).length !== 0
    ) {
      setHistoryBtnConfig({ msg: 'Select a WALE to see change history', disabled: true });
    } else if (!configChanges || configChanges.length === 0) {
      setHistoryBtnConfig({ msg: 'No config changes found in the past month', disabled: true });
    } else {
      setHistoryBtnConfig({ msg: 'View change history', disabled: false });
    }
  }, [selectedState, configChanges, possibleExtraOptions]);

  const handleToggleChange = () => {
    setShowOnlyActive(!showOnlyActive);
  };
  const handleShowChangeHistory = () => {
    setShowHistoryModal(true);
  };
  const handleHideChangeHistory = () => {
    setShowHistoryModal(false);
  };

  const getWaleChanges = () => {
    let usedConfigChanges = configChanges;
    if (hideBrightnessSettings) {
      usedConfigChanges = configChanges
        .map((configChange) => ({
          ...configChange,
          changes: configChange.changes.filter(
            (change) => !BRIGHTNESS_SETTINGS.includes(`configurations.${change.config}`)
          )
        }))
        .filter((configChange) => configChange.changes.length);
    }
    return (
      <div>
        <Col>
          <Row>
            <Col xs={2} lg={1}>
              <Toggle
                defaultChecked={hideBrightnessSettings}
                onChange={() => setHideBrightnessSettings((val) => !val)}
              />
            </Col>
            <Col xs={10} lg={11}>
              Hide Brightness changes
            </Col>
          </Row>
        </Col>
        {usedConfigChanges.map((configChange) => (
          <div key={configChange.date + configChange.author}>
            <Row>
              <Col>{`${configChange.author ?? '<unknown>'}, ${getRoadDateTime(
                configChange.date
              )}:`}</Col>
            </Row>
            {configChange.changes.map((change) => (
              <Row key={change.config + change.oldValue + change.newValue}>
                <Col lg={7} className="ml-4">
                  {change.config}
                </Col>
                <Col lg={3}>{`${change.oldValue} → ${change.newValue}`}</Col>
              </Row>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Row className="full-height">
      <Col md={12} className="bliss-card my-3">
        <Row>
          <Col md={1} xs={4}>
            <BackToHomeBtn />
          </Col>
          <Col md={9} xs={4} className="text-center">
            <h2>
              <span className="text-blue text-slim text-uppercase">WALE Settings</span>
            </h2>
          </Col>
          <Col>
            <Select
              placeholder="Select WALEs"
              styles={customStyles}
              isClearable
              options={[...extraSelectedOptions, ...mapToOptions(waleList)]}
              defaultValue={selectedState}
              value={selectedState}
              onChange={handleWaleSelectionChange}
            />
          </Col>
          <Col md={12} xs={12}>
            <Row>
              <Modal size="lg" show={showHistoryModal} onHide={handleHideChangeHistory}>
                <Modal.Header closeButton>
                  <Modal.Title>{selectedState} changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>{configChanges.length !== 0 && getWaleChanges()}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleHideChangeHistory}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <Col>
                <Button
                  className="history-toggle"
                  disabled={historyBtnConfig.disabled}
                  variant="link"
                  onClick={handleShowChangeHistory}
                >
                  <Clock /> {historyBtnConfig.msg}
                </Button>
              </Col>
              <Col className="active-toggle">
                <Toggle defaultChecked onChange={handleToggleChange} />
                Only Active WALEs
              </Col>
            </Row>
            <ConfigDataTable
              columns={columns}
              displayedWALEs={displayedWALEs}
              reRenderNumber={reRenderCount}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default WaleSettings;
